import { createStore } from 'vuex'

export default createStore({
  state: {
    infousuario: {},
    sedeactiva: {}
  },
  mutations: {

    almacenardatosusuario: (state, payload) => {
      state.infousuario = payload
    },

    registrarsede: (state, payload) => {
      state.sedeactiva = payload
    },

  },
  actions: {

    accionRegistrarUsuario({commit}, usuario){
      commit('almacenardatosusuario', usuario)

    },
    accionSedeActiva({commit}, infosede){
      commit('registrarsede', infosede)
    } 

  },
  getters: {
   
  },  
  modules: {
  }
})
