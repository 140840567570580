import { createRouter, createWebHistory } from 'vue-router'
import VueRouter from 'vue-router'
import vuex from 'vuex'
import store from '../store'

const routes = [

  { path: '/login', name: 'login', component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')  },
  { 
    path: '/', name: 'Plantilla', redirect: { name: 'Escritorio' }, component: () => import(/* webpackChunkName: "Plantilla" */ '../views/Plantilla.vue'), 
    meta: { rutaprotegida: true},
    children: [

              { path: '/Escritorio',  name: 'Escritorio',     component: () => import(/* webpackChunkName: "Escritorio" */ '../views/Escritorio.vue'), meta: { rutaprotegida: true} },
              { path: '/perfil',      name: 'perfil',    component: () => import(/* webpackChunkName: "Infousuario" */ '../views/personas/Perfil.vue'), meta: { rutaprotegida: true}  }, 
              { path: '/salir',       name: 'Salir',          component: () => import(/* webpackChunkName: "Salir" */ '../views/Salir.vue'), meta: { rutaprotegida: true}   }, 

              // Personal
              { path: '/personal',              name: 'Personal',                   component: () => import(/* webpackChunkName: "Personal" */ '../views/personal/Personal')   },
              { path: '/infopersonal/:id',      name: 'Infopersonal',               component: () => import(/* webpackChunkName: "Infopersonal" */ '../views/personal/Infopersonal'), meta: { rutaprotegida: true} },
              { path: '/nuevopersonal/',        name: 'Nuevopersonal',              component: () => import(/* webpackChunkName: "Nuevopersonal" */ '../views/personal/Nuevopersonal'), meta: { rutaprotegida: true} },
              
              // Personas
              { path: '/editarpersona/:id', props: true,     name: 'Editarpersona',              component: () => import(/* webpackChunkName: "Editarpersona" */ '../views/personas/Editarpersona'), meta: { rutaprotegida: true} },

              // estudiantes
              { path: '/estudiantes',         name: 'estudiantes',               component: () => import(/* webpackChunkName: "Estudiantes" */ '../views/Estudiantes/Estudiantes'), meta: { rutaprotegida: true}   },
              { path: '/matriculaprint/:id',  name: 'Matriculaprint',    props: true,   component: () => import(/* webpackChunkName: "Estudiantes" */ '../views/Estudiantes/Matriculaprint'), meta: { rutaprotegida: true}   },
              { path: '/infoestudiante/:id',  props: true,   name: 'infoestudiante',   component: () => import(/* webpackChunkName: "Infoestudiantes" */ '../views/Estudiantes/Infoestudiantes'), meta: { rutaprotegida: true },
                children: [
                  { path: '/datosestudiantes/:id',    props: true,   name: 'datosestudiantes',   component: () => import(/* webpackChunkName: "datosestudiantes" */ '../views/personas/Datospersonales'), meta: { rutaprotegida: true} } ,
                  { path: '/historialmatricula/:id',  props: true,   name: 'historialmatricula',   component: () => import(/* webpackChunkName: "Infoestudiantes" */ '../views/Estudiantes/Historialmatricula'), meta: { rutaprotegida: true} }, 
                  { path: '/EncuestasPersona/:id',    props: true,   name: 'EncuestasPersona',   component: () => import(/* webpackChunkName: "EncuestasPersona" */ '../views/encuestas/EncuestasPersona'), meta: { rutaprotegida: true} } 
                ]
              },
              { path: '/listaestudiantes',             name: 'ListaEstudiantes',               component: () => import(/* webpackChunkName: "Estudiantes" */ '../views/Estudiantes/ListaEstudiantes'), meta: { rutaprotegida: true}   },

              //Matriculas Academicas
              { path: '/matriculaestudiantes',   name: 'NuevoEstudiante',  props: true,   component: () => import(/* webpackChunkName: "NuevoEstudiante" */ '../views/Estudiantes/NuevoEstudiante'), meta: { rutaprotegida: true} },
              { path: '/cematricula/:id/:accion/:idmatricula',    name: 'cematricula',  props: true,   component: () => import(/* webpackChunkName: "EditarMatricula" */ '../views/matricula/CrearEditarMatricula'), meta: { rutaprotegida: true} },

              //Academico - Administrador
              { path: '/clases',              name: 'listaclases',  props:true,  component: () => import(/* webpackChunkName: "listaclases" */ '../views/academia/Clases'), meta: { rutaprotegida: true} },
              { path: '/clase/:id',           name: 'infoclase',    props:true,  component: () => import(/* webpackChunkName: "infoclase" */ '../views/academia/Clase'), meta: { rutaprotegida: true} },
              { path: '/clases/:accion/:id',  name: 'clases',       props:true,  component: () => import(/* webpackChunkName: "clases" */ '../views/academia/Creareditarclase'), meta: { rutaprotegida: true} },
              { path: '/misclases',           name: 'misclases',       props:true,  component: () => import(/* webpackChunkName: "misclases" */ '../views/academia/Misclases'), meta: { rutaprotegida: true} },
              { path: '/misclases/:slug',     name: 'claseview',       props:true,  component: () => import(/* webpackChunkName: "claseview" */ '../views/academia/Claseview'), meta: { rutaprotegida: true} },
              { path: '/asignacionacademica', name: 'asignacionacademica',       props:true,  component: () => import(/* webpackChunkName: "asignacionacademica" */ '../views/academia/Asignacionacademica'), meta: { rutaprotegida: true} },
              { path: '/informesacademicos',  name: 'informesacademicos',       props:true,  component: () => import(/* webpackChunkName: "Grados" */ '../views/academia/Informesacademicos'), meta: { rutaprotegida: true} },
              { path: '/calificaciones',      name: 'calificaciones',       props:true,  component: () => import(/* webpackChunkName: "Grados" */ '../views/academia/Calificar'), meta: { rutaprotegida: true} },
              { path: '/competenciasacademicas',  name: 'competenciasacademicas',       props:true,  component: () => import(/* webpackChunkName: "Grados" */ '../views/academia/Competenciasacademicas'), meta: { rutaprotegida: true} },

                //Academico -> Usuario
                { path: '/estudiantes/informes',  name: 'estudiantesinformes',       props:true,  component: () => import(/* webpackChunkName: "Grados" */ '../views/academia/Estudiantesinformes'), meta: { rutaprotegida: true} },
              
              //Publicaciones y Contenidos para la web y la Intranet
              { path: '/publicaciones',             name: 'publicaciones',          props: true,   component: () => import(/* webpackChunkName: "publicaciones" */ '../views/publicaciones/Publicaciones'), meta: { rutaprotegida: true} },
              { path: '/infopublicacion/:id',       name: 'infopublicacion',        props: true,   component: () => import(/* webpackChunkName: "infopublicacion" */ '../views/publicaciones/Infopublicacion'), meta: { rutaprotegida: true} },
              { path: '/publicacionview/:id',       name: 'publicacionview',        props: true,   component: () => import(/* webpackChunkName: "publicacionview" */ '../views/publicaciones/Publicacionview'), meta: { rutaprotegida: true} },
              { path: '/publicaciones/:accion/:id', name: 'nuevapublicacion',       props: true,   component: () => import(/* webpackChunkName: "Creareditarpublicacion" */ '../views/publicaciones/Creareditarpublicacion'), meta: { rutaprotegida: true} },
              { path: '/categoriapublicaciones',    name: 'categoriapublicaciones', props: true,   component: () => import(/* webpackChunkName: "categoriapublicaciones" */ '../views/publicaciones/Categoriapublicaciones'), meta: { rutaprotegida: true} },

              //Encuestas
              { path: '/encuestas',           name: 'encuestas',      component: () => import(/* webpackChunkName: "encuestas" */ '../views/encuestas/Encuestas'),  meta: { requiresAuth: true } },
              { path: '/nuevaencuesta',       name: 'nuevaencuesta',    component: () => import(/* webpackChunkName: "nuevaencuesta" */ '../views/encuestas/Nuevaencuesta'),  meta: { requiresAuth: true } },
              { path: '/infoencuesta/:id',    name: 'infoencuesta',   props: true, component: () => import(/* webpackChunkName: "infoencuesta" */ '../views/encuestas/Infoencuesta'),  meta: { requiresAuth: true } },
              { path: '/crearpregunta/:id',   name: 'crearpregunta',  props: true,  component: () => import(/* webpackChunkName: "Crearpregunta" */ '../views/encuestas/Crearpregunta'),  meta: { requiresAuth: true } },
              { path: '/respuestaspersona/:idpersona/:idencuesta',    props: true,  name: 'respuestaspersona',   component: () => import(/* webpackChunkName: "Crearpregunta" */ '../views/encuestas/RespuestasPersona'),  meta: { requiresAuth: true } },
              
              //Configuracion
              { path: '/permisos',              name: 'Permisos', component: () => import(/* webpackChunkName: "Permisos" */ '../views/configuracion/Permisos'),  meta: { requiresAuth: true } },
              { path: '/creareditarpermiso/:id/:accion',  props: true,    name: 'CrearEditarPermiso', component: () => import(/* webpackChunkName: "CrearEditarPermiso" */ '../views/configuracion/CrearEditarPermiso'),  meta: { requiresAuth: true } },
              { path: '/asignacionindividual',  props: true,    name: 'Asignacionindividual', component: () => import(/* webpackChunkName: "Asignacionindividual" */ '../views/configuracion/Asignacionindividual'),  meta: { requiresAuth: true } },
              { path: '/asignaciongrupal',  props: true,    name: 'AsignacionGrupal', component: () => import(/* webpackChunkName: "AsignacionGrupal" */ '../views/configuracion/Asignaciongrupal'),  meta: { requiresAuth: true } },
              { path: '/accesousuarios',  props: true,    name: 'accesousuarios', component: () => import(/* webpackChunkName: "accesousuarios" */ '../views/configuracion/Accesousuarios'),  meta: { requiresAuth: true } },

              ]
  }
]

 
 
const router = createRouter({
    history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

   if(to.meta.rutaprotegida){     
      if(localStorage.getItem("token")){
          next()
      }else{
          next({
            path: '/login',
          })
      }
   }else{
          next()
   }

   
})
export default router
