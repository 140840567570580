import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//ESTIOS PROPIOS
import css from '../public/estilos.css';

//ICONOS FONTAWS
import iconos from '../public/iconos/css/all.min.css'
import swal from 'sweetalert';

//URL del BACKEND API
const url = "https://api.apa.edu.co";
// const url = "http://localhost/APA/api.apa.edu.co/public";

import axios from 'axios'
axios.defaults.baseURL = url;
axios.defaults.headers =  { Authorization: "Bearer " + localStorage.getItem("token"), 'Access-Control-Allow-Origin': '*' };
   
import moment from 'moment'
moment.locale('es');

const app = createApp(App)
app.use(store)
app.use(router)
app.use(moment)
// app.config.globalProperties.archivospublicos = 'https://api.apa.edu.co/storage'
app.config.globalProperties.archivospublicos = url+'/storage'
app.config.globalProperties.imglocal = 'https://bait.apa.edu.co'
app.mount('#app')